import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import Icon from '../../components/icon/Icon';
import CreateModalCost from '../../pages/costs/cost-create/createModal';
import CreateModalIncome from '../../pages/incomes/income-create/createModal';
import { CostService } from '../../services/costs/costService';
import { IncomeService } from '../../services/incomes/incomeService';
import '../../styles/scheduler.css';
import { FixNumber } from '../../utils/fixNumber';

interface Item {
    id: string;
    title: string;
    estimatedEntryDate: string;
    price: number;
    typeId: string;
    level: number;
    paid: boolean;
    effectiveEntryDate: string;
    color: string;
}

interface Node {
    id: string;
    name: string;
    parent: string | null;
    children: Node[];
    incomes?: Item[];
    costs?: Item[];
}

interface ResourcesComponentProps {
    data: Node[];
    days: Date[];
    mode: string;
    entity: 'incomes' | 'costs';
    refetch: () => void;
}

const ResourcesComponent: React.FC<ResourcesComponentProps> = ({ data, days, mode, entity, refetch }) => {

    const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [selectedData, setSelectedData] = useState<Item | null>(null);

    /**
     * Fetch data for the selected item
     */
    const fetchData = async (id: string, entity: 'incomes' | 'costs') => {
        const service = entity === 'incomes' ? new IncomeService().getIncomeById(id) : new CostService().getCostById(id);
        const response = (await service).getResponseData();
        if (response.success) {
            setSelectedData(response.data);
        } else {
            toast.error(response.message);
        }
    };

    /**
     * Close edit modal and refetch data
     */
    const _onCloseEditModal = () => {
        setIsOpen(false);
        setSelectedData(null);
        refetch();
        toast.success('Ingreso modificado correctamente');
    };

    /**
     * Get all node ids with children
     */
    const getAllNodeIdsWithChildren = (nodes: Node[]): Set<string> => {
        const nodeIds = new Set<string>();
        const addNodeIds = (nodes: Node[]) => {
            nodes.forEach(node => {
                nodeIds.add(node.id);
                if (node.children.length > 0) {
                    addNodeIds(node.children);
                }
            });
        };
        addNodeIds(nodes);
        return nodeIds;
    };

    /**
     * Toggle node expansion state
     */
    const toggleNode = (id: string) => {
        setExpandedNodes(prev => {
            // Create a new set to trigger a re-render
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    /**
     * Calculate totals for each day based on the given filter function
     */
    const calculateTotals = (nodes: Node[], days: any[], filterFn: (income: Item) => boolean): number[] => {
        const totals = new Array(days.length).fill(0);

        const addItems = (node: Node) => {
            const items = entity === 'incomes' ? node.incomes : node.costs;
            items?.forEach(income => {
                if (filterFn(income)) {
                    const incomeDate = new Date(income.effectiveEntryDate !== null ? income.effectiveEntryDate : income.estimatedEntryDate);
                    days.forEach((day, index) => {
                        if (mode === 'day' && incomeDate.toDateString() === new Date(day).toDateString()) {
                            totals[index] += income.price;
                        } else if (mode === 'week' && incomeDate >= day.start && incomeDate <= day.end) {
                            totals[index] += income.price;
                        } else if (mode === 'month' && incomeDate >= day.start && incomeDate <= day.end) {
                            totals[index] += income.price;
                        }
                    });
                }
            });
            node.children.forEach(child => addItems(child));
        };

        nodes.forEach(node => addItems(node));
        return totals;
    };

    const calculateDailyTotals = (nodes: Node[], days: Date[]) => calculateTotals(nodes, days, () => true);

    const calculateDailyPendingTotals = (nodes: Node[], days: Date[]) => calculateTotals(nodes, days, (income) => !income.paid);

    const calculateDailyPaidTotals = (nodes: Node[], days: Date[]) => calculateTotals(nodes, days, (income) => income.paid);

    /**
     * Render rows recursively for each node and its children
     */
    const renderRows = (nodes: Node[], level: number = 0): any => {
        return nodes.flatMap(node => {
            const isExpanded = expandedNodes.has(node.id);
            const hasChildren = node.children.length > 0;

            return [
                <tr key={node.id}>
                    <OverlayTrigger overlay={<Tooltip id={node.id}>{node.name}</Tooltip>} key={node.id}>
                        <td className={`fixed-column resources level-${level}`}>
                            {node.name.length > 50 ? `${node.name.substring(0, 50)}...` : node.name}
                            {hasChildren && (
                                <button onClick={() => toggleNode(node.id)} className='btn p-1'>
                                    {isExpanded ? <Icon icon='ExpandLess' size={'lg'} /> : <Icon icon='ExpandMore' size={'lg'} />}
                                </button>
                            )}
                        </td>
                    </OverlayTrigger>
                    {days.map((day: any, index) => (
                        <td key={index} className='cells'>
                            {(entity === 'incomes' ? node.incomes : node.costs)
                                ?.filter(item => {
                                    const itemDate = new Date(
                                        item.paid
                                            ? (item.effectiveEntryDate ? item.effectiveEntryDate : item.estimatedEntryDate)
                                            : item.estimatedEntryDate
                                    );
                                    if (mode === 'day') {
                                        return itemDate.toDateString() === new Date(day).toDateString();
                                    } else if (mode === 'week') {
                                        return itemDate >= day.start && itemDate <= day.end;
                                    } else if (mode === 'month') {
                                        return itemDate >= day.start && itemDate <= day.end;
                                    }
                                    return false;
                                })
                                .map(item => (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={item.id}>
                                                Título: {item.title}<br />
                                                {item.paid ? (item.effectiveEntryDate ? `Pagado el ${moment(item.effectiveEntryDate).format('DD-MM-YYYY')}` : 'Pagado') : 'Pendiente de pago'}<br />
                                            </Tooltip>
                                        }
                                        key={item.id}
                                    >
                                        <div
                                            className={classNames('events cursor-pointer', { 'event-paid': item.paid, 'event-pending': !item.paid })}
                                            onClick={() => { setIsOpen(true); setSelectedItem(item.id); }}
                                        >
                                            <span style={{ color: item.color }}>{FixNumber(item.price)}€</span>
                                        </div>
                                    </OverlayTrigger>
                                ))
                            }
                        </td>
                    ))}
                </tr>,
                isExpanded && renderRows(node.children, level + 1)
            ].filter(Boolean); // Filter to remove possible undefined values
        });
    };

    /**
     * Initialize expanded nodes with all nodes that have children
     */
    useEffect(() => {
        setExpandedNodes(getAllNodeIdsWithChildren(data));
    }, [data]);

    /**
     * Fetch data for the selected item
     */
    useEffect(() => {
        if (selectedItem) {
            fetchData(selectedItem, entity);
        }
    }, [selectedItem, entity]);

    return (
        <>
            <tbody>
                {renderRows(data)}

                <tr className="total-pending">
                    <td className="fixed-column totals fw-bold">Pendiente de pago</td>
                    {calculateDailyPendingTotals(data, days).map((total, index) => (
                        <td key={index} className="totals">
                            {total !== 0 && `${FixNumber(total)}€`}
                        </td>
                    ))}
                </tr>

                <tr className="total-paid">
                    <td className="fixed-column totals fw-bold">Pagado</td>
                    {calculateDailyPaidTotals(data, days).map((total, index) => (
                        <td key={index} className="totals">
                            {total !== 0 && `${FixNumber(total)}€`}
                        </td>
                    ))}
                </tr>

                <tr className="totals-row">
                    <td className="fixed-column totals fw-bold">Total Diario</td>
                    {calculateDailyTotals(data, days).map((total, index) => (
                        <td key={index} className="totals">
                            {total !== 0 && `${FixNumber(total)}€`}
                        </td>
                    ))}
                </tr>
            </tbody>

            {isOpen && (entity === 'incomes'
                ? <CreateModalIncome isOpen={isOpen} setIsOpen={setIsOpen} data={selectedData} onClose={_onCloseEditModal} />
                : <CreateModalCost isOpen={isOpen} setIsOpen={setIsOpen} data={selectedData} onClose={_onCloseEditModal} />
            )}
        </>
    );
};

export default ResourcesComponent;