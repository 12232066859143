import Card, { CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { changeProjectActiveTab } from "../../../redux/projectSlice";
import { ProjectInfoView } from "../components/ProjectInfoView";
import useFetch from "../../../hooks/useFetch";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { ProjectService } from "../../../services/projects/projectService";
import { ProjectsApiResponse } from "../../../type/project-type";
import { useNavigate, useParams } from "react-router-dom";
import ProjectChat from "../../../components/chat/ProjectChat";
import { Row } from "react-bootstrap";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { UsersWithAccess } from "../components/UsersWithAccess";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import moment from "moment";
import { Status } from "../../../type/status-type";
import { StatusService } from "../../../services/status/statusService";
import StatusesTimeLine from "../components/StatusesTimeLine";
import StagesInvoices from "../components/StagesInvoices";
import DirectoryNavigatorProvider from "../../../components/DirectoryNavigator/DirectoryNavigatorProvider";
import DirectoryNavigator from "../../../components/DirectoryNavigator/DirectoryNavigator";
import { RecentProjectDocuments } from "../components/RecentProjectDocuments";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { TasksCalendarProvider } from "../../calendar/providers/TasksCalendarProvider";
import { TasksCalendar } from "../../calendar/tasks-calendar/TasksCalendar";
import Spinner from "../../../components/bootstrap/Spinner";

export const ProjectView = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { id = '' } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeTab = useSelector((state: any) => state.project_info.activeTab);

    const projectService = new ProjectService();

    const [projectCreators, setProjectCreators] = useState<string[]>([]);
    const [projectStatuses, setProjectStatuses] = useState<Status[]>([]);
    const [topic, setTopic] = useState<string>('');

    const [project, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await projectService.getProject(id);
        return response.getResponseData() as ProjectsApiResponse;
    }, [id]));

    const [statusChanges, loadingChanges, changesError, refetchChanges] = useFetch(useCallback(async () => {
        const response = await (new ProjectService()).listStatusChanges(id);
        return response.getResponseData();
    }, []));

    const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
        //if (!userCan("down_status", "statuses")) return;
        const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
        if (response.success) {
            return response.data.statuses;
        } else {
            return [];
        }
    };

    // Carga los estados de los proyectos
    const fetchStatuses = async () => {
        let projectStatusList = await getStatusList("project");
        setProjectStatuses(projectStatusList !== undefined ? projectStatusList : []);
    };

    const dispatchSelectedTab = (tabId: string) => {
        dispatch(changeProjectActiveTab(tabId));
    };

    useEffect(() => {
        if (project) {
            document.title = project.codeIdentifier; // Cambia el título de la página por el código del proyecto

            setProjectCreators([project.creator?.id, project.planner?.id]); // Carga los creadores del proyecto

            if (project.chat[0]) {
                setTopic(project.chat[0].id); // Actualiza el topic del chat
            }

            fetchStatuses(); // Carga los estados de los proyectos
        }
    }, [project]);

    if (loading) return <div className="text-center mt-5"><Spinner /></div>;
    if (!project) return null;

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft className="w-75">
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle className="me-4"><span className="text-muted fs-6">Proyecto: </span>{project?.budget?.name}</CardTitle>
                    <CardTitle className="text-nowrap"><span className="text-muted fs-6">Código: </span>{project?.codeIdentifier}</CardTitle>
                    <SubheaderSeparator />
                    <UsersWithAccess users={projectCreators} />
                </SubHeaderLeft>
                <SubHeaderRight className="w-25">
                    <StatusesDropdown
                        onChange={() => { refetch(); refetchChanges(); }}
                        key={project.id + "-status"}
                        itemId={project.id}
                        statuses={projectStatuses}
                        status={
                            project.projectStatus != null
                                ? project.projectStatus
                                : null
                        }
                        additionalInfo={
                            project.projectStatusDate
                                ? moment(project.projectStatusDate.date).format("DD/MM/yyyy HH:mm")
                                : undefined
                        }
                        orientedTo={"project"}
                        startEnd
                    />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Row className="g-4">
                    <div className="col-md-12">
                        <Card hasTab stretch>
                            <CardTabItem id='info' title='Información' icon='Info' onSelected={dispatchSelectedTab} isActive={activeTab === 'info'}>
                                <Row>
                                    <div className="col-md-8">
                                        <ProjectInfoView projectData={project} />
                                    </div>
                                    <div className="col-md-4">
                                        <StatusesTimeLine statusChanges={statusChanges} />
                                    </div>
                                </Row>
                            </CardTabItem>

                            <CardTabItem id='documents' title='Documentos' icon='FolderOpen' onSelected={dispatchSelectedTab} isActive={activeTab === 'documents'}>
                                {
                                    userCan("list", "project_document_type") ? (
                                        <DirectoryNavigatorProvider>
                                            <Row>
                                                <div className="col-md-9 pe-5">
                                                    <DirectoryNavigator />
                                                </div>
                                                <div className="col-md-3">
                                                    <RecentProjectDocuments />
                                                </div>
                                            </Row>
                                        </DirectoryNavigatorProvider>
                                    ) : <h5 className="text-muted text-center">No tienes acceso a este recurso</h5>
                                }
                            </ CardTabItem>

                            <CardTabItem id='invoices' title='Facturación' icon='Receipt' onSelected={dispatchSelectedTab} isActive={activeTab === 'invoices'}>
                                <StagesInvoices id={project?.budget?.id} />
                            </CardTabItem>

                            <CardTabItem id='tasks' title='Tareas' icon='TaskAlt' onSelected={dispatchSelectedTab} isActive={activeTab === 'tasks'}>
                                <TasksCalendarProvider projectView>
                                    <TasksCalendar />
                                </TasksCalendarProvider>
                            </CardTabItem>

                            <CardTabItem id='chat' title='Chat' icon='Chat' onSelected={dispatchSelectedTab} isActive={activeTab === 'chat'}>
                                {topic !== '' && <ProjectChat chatData={project?.chat[0]} topic={topic} />}
                            </ CardTabItem>
                        </Card>
                    </div>
                </Row>
            </Page>
        </Fragment>
    );
};